import styled from "@emotion/styled";
import { linkStyle } from "./linkStyle";

const LinkExternal = styled.a<{dark?: boolean}>`

  ${linkStyle};
  color: ${p => (p.dark ? p.theme.colors.textSecondaryDark : 'inherit')};
`;

export default LinkExternal;
