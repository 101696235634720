import React, { useEffect } from 'react';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import { useColorMode } from 'theme-ui';

import NavigationFooter from '@components/Navigation/Navigation.Footer';
import NavigationHeader from '@components/Navigation/Navigation.Header';
import ArticlesContextProvider from '../../sections/articles/Articles.List.Context';

import { globalStyles } from '@styles';

/**
 * <Layout /> needs to wrap every page as it provides styles, navigation,
 * and the main structure of each page. Within Layout we have the <Container />
 * which hides a lot of the mess we need to create our Desktop and Mobile experiences.
 */
 interface LayoutProps {
  // pageTheme: boolean,
  isDark: boolean,
}

const Layout: React.FC<LayoutProps> = ({ isDark, children }) => {
  const [colorMode, setColorMode] = useColorMode();
  // const isDark = colorMode === `dark`;


  // function toggleColorMode(event) {
  //   event.preventDefault();
  //   setColorMode(pageTheme === isDark ? `dark` : `light`);
  // }
  
  useEffect(() => {
    parent.postMessage({ theme: colorMode }, '*');
    // toggleColorMode;
  }, [colorMode]);

  return (
    <ArticlesContextProvider>
      <Container dark = {isDark}>
        <Global styles={globalStyles} />
        <NavigationHeader isDark = {isDark}/>
        {children}
        <NavigationFooter isDark = {isDark}/>
      </Container>
    </ArticlesContextProvider>
  );
}

export default Layout;

const Container = styled.div<{dark?: boolean}>`
  position: relative;
  background: ${p => (p.dark ? p.theme.colors.backgroundDark : p.theme.colors.background)};
  transition: ${p => p.theme.colorModeTransition};
  min-height: 100vh;
`;
