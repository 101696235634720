import React from "react";

import { Icon } from '@types';

const ArrowForward: Icon = ({ fill }) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.611 13.812a.987.987 0 0 0-.307-.721l-5.792-5.775a1.132 1.132 0 0 0-.334-.237.89.89 0 0 0-.37-.079.935.935 0 0 0-.676.264.895.895 0 0 0-.264.659c0 .14.024.27.07.387.053.11.123.21.211.298l1.97 1.995 3.277 2.998.308-.572-3.05-.184H6.976c-.293 0-.53.09-.712.272-.176.176-.264.407-.264.694 0 .282.088.513.264.695.181.176.419.264.712.264h10.678l3.05-.176-.308-.563-3.278 2.989-1.969 1.986c-.087.088-.158.19-.21.308-.047.117-.07.243-.07.377 0 .264.087.484.263.66a.935.935 0 0 0 .677.263c.257 0 .489-.1.694-.299l5.8-5.783a.987.987 0 0 0 .308-.72Z"/>
  </svg>
);

export default ArrowForward;

